<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <h5>{{ $t('menu.popularLeagues') }}</h5>
                <Fieldset :legend="$t('sportsSettings.addNewPopularLeague')" :toggleable="true" :collapsed="true">
                    <div class="p-grid">
                        <div class="p-col-12 p-grid">
                            <label for="displayName" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{ $t('sportsSettings.sport') }} *</label>
                            <div class="p-col-12 p-md-4">
                                <Dropdown style="width: 100%" v-model="sportsModel" :options="sports" optionLabel="displayName" :placeholder="$t('dropdown.placeholder.sport')"  :filter="!this.isMobile()"  />
                            </div>
                        </div>
                        <div class="p-col-12 p-grid">
                            <label for="displayName" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{ $t('sportsSettings.category') }} *</label>
                            <div class="p-col-12 p-md-4">
                                <Dropdown style="width: 100%" v-model="categoriesModel" :options="filteredCategories" optionLabel="displayName" :placeholder="$t('dropdown.placeholder.category')"  :filter="!this.isMobile()"  />
                            </div>
                        </div>
                        <div class="p-col-12 p-grid">
                            <label for="displayName" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{ $t('sportsSettings.tournament') }} *</label>
                            <div class="p-col-12 p-md-4">
                                <Dropdown style="width: 100%" v-model="tournamentsModel" :options="filteredTournaments" optionLabel="displayName" :placeholder="$t('dropdown.placeholder.tournament')"  :filter="!this.isMobile()"  />
                            </div>
                        </div>
                        <div class="p-col-12 p-grid">
                            <label class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{ $t('sportsSettings.name') }} *</label>
                            <div class="p-col-12 p-md-4">
                                <InputText style="width: 100%" type="text" v-model="newLeague.Name" />
                            </div>
                        </div>
                        <div class="p-col-12 p-grid">
                            <label class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{ $t('sportsSettings.sortOrder') }} </label>
                            <div class="p-col-12 p-md-4">
                                <InputText style="width: 100%" type="text" v-model="newLeague.SortOrder" />
                            </div>
                        </div>
                        <div class="p-col-12">
                            <p>{{ $t('forms.mandatoryFieldsText') }}</p>
                        </div>
                        <div class="p-col-12 p-md-4">
                            <Button @click="addNewPopularLeague()" :label="$t('buttons.save')" class="p-button-raised p-button-success p-mr-2 p-mb-2" />
                        </div>
                        <transition-group name="p-message" tag="div">
                            <Message :key="errorKeyCount" :severity="'error'" v-show="isMaxLeagues">{{ $t('popularLeagues.maxLeaguesAlert', { count: maxLeagues }) }}</Message>
                        </transition-group>
                        <transition-group name="p-message" tag="div">
                            <Message :key="errorKeyCount + 1" :severity="'error'" v-show="showError">{{ errorText }}</Message>
                        </transition-group>
                    </div>
                </Fieldset>
                <DataTable :value="items" v-model:expandedRows="expandedRows" :loading="loading" class="p-mt-3" ref="datatable" responsiveLayout="scroll" stateStorage="session" stateKey="dt-state-popularLeagues">
                    <Column
                        ><template #body="slotProps">
                            <span class="indexColumn">{{ slotProps.index + 1 }}</span>
                        </template>
                    </Column>
                    <Column :expander="true" headerStyle="width: 3rem"></Column>
                    <Column field="name" :header="$t('sportsSettings.displayName')" :sortable="true"></Column>
                    <Column :header="$t('sportsSettings.sport')">
                        <template #body="slotProps"> {{ sportsDictionary[slotProps.data.sportId] }}</template></Column
                    >
                    <Column :header="$t('sportsSettings.category')">
                        <template #body="slotProps"> {{ categoriesDictionary[slotProps.data.categoryId] }}</template></Column
                    >
                    <Column :header="$t('sportsSettings.tournament')">
                        <template #body="slotProps"> {{ tournamentsDictionary[slotProps.data.tournamentId] }}</template></Column
                    >
                    <Column field="sortOrder" :header="$t('sportsSettings.sortOrder')" :sortable="true"></Column>
                    <Column :expander="true" headerStyle="width: 3rem"></Column>
                    <Column :exportable="false" class="p-text-right">
                        <template #body="slotProps">
                            <Button icon="pi pi-times" class="p-button-rounded p-button-danger p-mr-2" @click="removeFromPopular(slotProps.data._id)" />
                        </template>
                    </Column>
                    <template #expansion="slotProps">
                        <EditPopularLeague :league="slotProps.data"></EditPopularLeague>
                    </template>
                </DataTable>
            </div>
        </div>
    </div>
</template>
<script>
import { SportsSettingsApi } from '../../service/SportSettingsApi';
import { uiSettings } from '../../settings/uiSettings';
import EditPopularLeague from './Components/EditPopularLeague.vue';
export default {
    components: {
        EditPopularLeague,
    },
    data() {
        return {
            tableHeight: uiSettings.tableHeight,
            items: [],
            SportsSettingsApi: new SportsSettingsApi(),
            loading: true,
            crtRoute: 'popularLeagues',
            sports: [],
            sportsModel: {},
            sportsDictionary: {},
            categories: [],
            categoriesDictionary: {},
            categoriesModel: {},
            tournaments: [],
            tournamentsDictionary: {},
            tournamentsModel: {},
            newLeague: {
                SportId: 0,
                CategoryId: 0,
                TournamentId: 0,
                Name: '',
                SortOrder: 0,
            },
            maxLeagues: 10,
            errorKeyCount: 0,
            expandedRows: [],
            showError: false,
            errorText: '',
        };
    },
    computed: {
        filteredCategories() {
            if (this.sportsModel == undefined || this.sportsModel.id == '0') {
                return this.categories;
            } else {
                return this.categories.filter((c) => c.sportId == this.sportsModel.id);
            }
        },
        filteredTournaments() {
            if (this.categoriesModel == undefined || this.categoriesModel.id == '0') {
                return this.tournaments;
            } else {
                return this.tournaments.filter((c) => c.categoryId == this.categoriesModel.id);
            }
        },
        isMaxLeagues() {
            return this.items.length >= this.maxLeagues ? true : false;
        },
        editPage() {
            return this.crtRoute + '/edit';
        },
    },
    mounted() {
        this.getAllTournaments();
        this.getAllCategories();
        this.getAllSports();
        this.getPopularLeagues();
    },
    watch: {
        sportsModel() {
            this.newLeague.SportId = this.sportsModel.id;
        },
        categoriesModel() {
            this.newLeague.CategoryId = this.categoriesModel.id;
        },
        tournamentsModel() {
            this.newLeague.TournamentId = this.tournamentsModel.id;
        },
    },
    methods: {
        getPopularLeagues() {
            this.SportsSettingsApi.getPopularLeagues().then((response) => {
                this.items = response.data;
                this.loading = false;
            });
        },
        createSportsDictionary() {
            for (let i = 0; i < this.sports.length; i++) {
                this.sportsDictionary[this.sports[i].id] = this.sports[i].displayName;
            }
        },
        createCategoriesDictionary() {
            for (let i = 0; i < this.categories.length; i++) {
                this.categoriesDictionary[this.categories[i].id] = this.categories[i].displayName;
            }
        },
        createTournamentsDictionary() {
            for (let i = 0; i < this.tournaments.length; i++) {
                this.tournamentsDictionary[this.tournaments[i].id] = this.tournaments[i].displayName;
            }
        },
        getAllCategories() {
            this.SportsSettingsApi.getAllCategories('0').then((response) => {
                this.categories = response.data;
                this.createCategoriesDictionary();
            });
        },
        getAllSports() {
            this.SportsSettingsApi.getAllSports().then((response) => {
                this.sports = response.data;
                this.createSportsDictionary();
            });
        },
        getAllTournaments() {
            this.SportsSettingsApi.getAllTournaments(0, 0).then((response) => {
                this.tournaments = response.data;
                this.createTournamentsDictionary();
            });
        },
        removeFromPopular(leagueId) {
            this.SportsSettingsApi.removeFromPopulareLeagues(leagueId)
                .then(() => {
                    this.$router.go();
                })
                .catch(() => {});
        },
        addNewPopularLeague() {
            if (this.notNullOrEmptyField(this.newLeague.SportId) && this.notNullOrEmptyField(this.newLeague.CategoryId) && this.notNullOrEmptyField(this.newLeague.TournamentId) && this.notNullOrEmptyField(this.newLeague.Name)) {
                this.SportsSettingsApi.addPopularLeague(this.newLeague).then(() => {
                    this.$router.go();
                });
            } else {
                this.errorText = this.$t('forms.mandatoryFieldsMessage');
                this.showError = true;
                this.disableSubmit = false;
                this.errorKeyCount++;
            }
        },
    },
};
</script>
