<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <h6>{{ $t('popularLeagues.editPopularLeague') }}</h6>
                <div class="p-field p-grid">
                    <label for="sportName" class="p-col-12 p-mb-2 p-md-1 p-mb-md-0">{{ $t('sportsSettings.name') }} *</label>
                    <div class="p-col-12 p-md-2">
                        <InputText style="width: 100%" id="sportName" type="text" v-model="leagueObj.name" />
                    </div>
                </div>
                <div class="p-field p-grid">
                    <label for="displayName" class="p-col-12 p-mb-2 p-md-1 p-mb-md-0">{{ $t('sportsSettings.displayName') }} *</label>
                    <div class="p-col-12 p-md-2">
                        <InputText style="width: 100%" id="displayName" type="text" v-model="leagueObj.sortOrder" />
                    </div>
                </div>
                <Button @click="saveLeague()" :label="$t('buttons.save')" class="p-button-raised p-button-success p-mr-2 p-mb-2" />
            </div>
        </div>
    </div>
</template>
<script>
import { SportsSettingsApi } from '../../../service/SportSettingsApi';
export default {
    data() {
        return {
            SportsSettingsApi: new SportsSettingsApi(),
        };
    },
    props: {
        league: {
            type: Object,
            required: true,
        },
    },
    computed: {
        leagueObj() {
            return { ...this.league };
        },
    },
    methods: {
        saveLeague() {
            this.SportsSettingsApi.updatePopularLeague(this.leagueObj)
                .then(() => {
                    this.$router.go();
                })
                .catch(() => {});
        },
        showId() {
            return this.$route.params.id !== '0';
        },
    },
};
</script>
